import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'IBULL',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      // 56: '0x403323BD6133dCE97303F3c48DD7056d330879EB', /* 0x3E76Bd1EDb5f6d981DD14d9D2ab711F0C2a0A6A0 */
      56: '0x22d2376f03d48fce86b696ded1a41c5bd02f28d3',
    },
    token: tokens.ibull,
    quoteToken: tokens.busd,
  },
  {
    pid: 1,
    lpSymbol: 'IBULL-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xe4853dcc94e44c80336de8bb0e293db7dd359cf4',
    },
    token: tokens.ibull,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'IBULL-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x22d2376f03d48fce86b696ded1a41c5bd02f28d3',
    },
    token: tokens.ibull,
    quoteToken: tokens.busd,
  },
  {
    pid: 3,
    lpSymbol: 'IBULL-CAKE LP',
    lpAddresses: {
      97: '',
      56: '0x3a54514de1dd257e637ea14d4b6cebc2fc1e300a',
    },
    token: tokens.ibull,
    quoteToken: tokens.cake,
  },
  {
    pid: 13,
    lpSymbol: 'IBULL-USDT LP',
    lpAddresses: {
      97: '',
      56: '0xE0C536c27Ea3ad37ad72327e57a2D709E7a06690',
    },
    token: tokens.ibull,
    quoteToken: tokens.usdt,
  },
  {
    pid: 14,
    lpSymbol: 'IBULL-UST LP',
    lpAddresses: {
      97: '',
      56: '0x87Bdb84B342e83a5fB8220eD029539d1A3fb43DE',
    },
    token: tokens.ibull,
    quoteToken: tokens.ust,
  },
  {
    pid: 15,
    lpSymbol: 'IBULL-LTC LP',
    lpAddresses: {
      97: '',
      56: '0x3876F1089D802Cacd15Ba43F56F315F66ef68bB5',
    },
    token: tokens.ibull,
    quoteToken: tokens.ltc,
  },
  {
    pid: 16,
    lpSymbol: 'IBULL-BTCB LP',
    lpAddresses: {
      97: '',
      56: '0xFb462F35B19092eACE27660a12B954D4201cc027',
    },
    token: tokens.ibull,
    quoteToken: tokens.btcb,
  },
  {
    pid: 17,
    lpSymbol: 'IBULL-ETH LP',
    lpAddresses: {
      97: '',
      56: '0x8A2F6DdD974cd1d95F89cB849e1d7c9069b909bc',
    },
    token: tokens.ibull,
    quoteToken: tokens.eth,
  },
  {
    pid: 18,
    lpSymbol: 'IBULL-XRP LP',
    lpAddresses: {
      97: '',
      56: '0x95018282CCe4A53317Ae23e85aeB41bbbc4F1a6E',
    },
    token: tokens.ibull,
    quoteToken: tokens.xrp,
  },
  {
    pid: 27,
    lpSymbol: 'IBULL-SHIB LP',
    lpAddresses: {
      97: '',
      56: '0x4FE0A814C4F49aA4Af32F9DD60a07807cd29eEE0',
    },
    token: tokens.ibull,
    quoteToken: tokens.shib,
  },
  {
    pid: 25,
    lpSymbol: 'IBULL-DOGE LP',
    lpAddresses: {
      97: '',
      56: '0xE690Cc4f10f2Ed6E0ad5FBe18A8784CD5b8fDE50',
    },
    token: tokens.ibull,
    quoteToken: tokens.doge,
  },
  {
    pid: 4,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 5,
    lpSymbol: 'wBNB',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16' /* 0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c */,
    },
    token: tokens.wbnb,
    quoteToken: tokens.busd,
  },
  {
    pid: 6,
    lpSymbol: 'BUSD',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    },
    token: tokens.busd,
    quoteToken: tokens.busd,
  },
  {
    pid: 19,
    lpSymbol: 'BTCB',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0xF45cd219aEF8618A92BAa7aD848364a158a24F33',
    },
    token: tokens.btcb,
    quoteToken: tokens.busd,
  },
  {
    pid: 20,
    lpSymbol: 'ETH',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0xEa26B78255Df2bBC31C1eBf60010D78670185bD0',
    },
    token: tokens.eth,
    quoteToken: tokens.usdc,
  },
  {
    pid: 21,
    lpSymbol: 'USDT',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x55d398326f99059ff775485246999027b3197955',
    },
    token: tokens.usdt,
    quoteToken: tokens.usdt,
  },
  {
    pid: 22,
    lpSymbol: 'USDC',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    },
    token: tokens.usdc,
    quoteToken: tokens.usdc,
  },
  {
    pid: 23,
    lpSymbol: 'TRX',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0xb5d108578be3750209d1b3a8f45ffee8c5a75146',
    },
    token: tokens.trx,
    quoteToken: tokens.busd,
  },
  {
    pid: 33,
    lpSymbol: 'ADA',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    },
    token: tokens.ada,
    quoteToken: tokens.busd,
  },
  {
    pid: 34,
    lpSymbol: 'DOGE',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0xba2ae424d960c26247dd6c32edc70b295c744c43',
    },
    token: tokens.doge,
    quoteToken: tokens.busd,
  },
  {
    pid: 35,
    lpSymbol: 'MATIC',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
    },
    token: tokens.matic,
    quoteToken: tokens.busd,
  },
  {
    pid: 36,
    lpSymbol: 'UNI',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0xbf5140a22578168fd562dccf235e5d43a02ce9b1',
    },
    token: tokens.uni,
    quoteToken: tokens.busd,
  },
  {
    pid: 37,
    lpSymbol: 'LTC',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
    },
    token: tokens.ltc,
    quoteToken: tokens.busd,
  },
  {
    pid: 38,
    lpSymbol: 'ATOM',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
    },
    token: tokens.atom,
    quoteToken: tokens.busd,
  },
  {
    pid: 39,
    lpSymbol: 'DOT',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    },
    token: tokens.dot,
    quoteToken: tokens.busd,
  },
  {
    pid: 40,
    lpSymbol: 'LINK',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
    },
    token: tokens.link,
    quoteToken: tokens.busd,
  },
  {
    pid: 41,
    lpSymbol: 'TUSD',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x14016e85a25aeb13065688cafb43044c2ef86784',
    },
    token: tokens.tusd,
    quoteToken: tokens.busd,
  },
  {
    pid: 42,
    lpSymbol: 'XRP',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    },
    token: tokens.xrp,
    quoteToken: tokens.busd,
  },
  {
    pid: 7,
    lpSymbol: 'BTCB-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
    },
    token: tokens.btcb,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 8,
    lpSymbol: 'ETH-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
    },
    token: tokens.eth,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 9,
    lpSymbol: 'USDC-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1',
    },
    token: tokens.usdc,
    quoteToken: tokens.busd,
  },
  {
    pid: 10,
    lpSymbol: 'TRX-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0xb5D108578Be3750209d1b3A8f45FFee8C5a75146',
    },
    token: tokens.trx,
    quoteToken: tokens.busd,
  },
  {
    pid: 11,
    lpSymbol: 'USDT-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE',
    },
    token: tokens.usdt,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 12,
    lpSymbol: 'XRP-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x03F18135c44C64ebFdCBad8297fe5bDafdBbdd86',
    },
    token: tokens.xrp,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 24,
    lpSymbol: 'CAKE-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x804678fa97d91B974ec2af3c843270886528a9E6',
    },
    token: tokens.cake,
    quoteToken: tokens.busd,
  },
  {
    pid: 26,
    lpSymbol: 'LTC-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x9B60DB1dA3BEc30ef144BA5a908147c562ff822A',
    },
    token: tokens.ltc,
    quoteToken: tokens.busd,
  },
  {
    pid: 28,
    lpSymbol: 'UST-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x05faf555522Fa3F93959F86B41A3808666093210',
    },
    token: tokens.ust,
    quoteToken: tokens.busd,
  },
  {
    pid: 29,
    lpSymbol: 'DOGE-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xac109C8025F272414fd9e2faA805a583708A017f',
    },
    token: tokens.doge,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 31,
    lpSymbol: 'ADA-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x28415ff2C35b65B9E5c7de82126b4015ab9d031F',
    },
    token: tokens.ada,
    quoteToken: tokens.wbnb,
  }, 
  {
    pid: 32,
    lpSymbol: 'AVAX-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0xDbA5cb3ADbbc055253F0Bb7cd84a883a40ae4f09',
    },
    token: tokens.avax,
    quoteToken: tokens.busd,
  },  
  {
    pid: 43,
    lpSymbol: 'BTCB-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0xF45cd219aEF8618A92BAa7aD848364a158a24F33',
    },
    token: tokens.btcb,
    quoteToken: tokens.busd,
  },
  {
    pid: 44,
    lpSymbol: 'BTCB-ETH LP',
    lpAddresses: {
      97: '',
      56: '0xD171B26E4484402de70e3Ea256bE5A2630d7e88D',
    },
    token: tokens.btcb,
    quoteToken: tokens.eth,
  },
  {
    pid: 45,
    lpSymbol: 'TUSD-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x2E28b9B74D6d99D4697e913b82B41ef1CAC51c6C',
    },
    token: tokens.tusd,
    quoteToken: tokens.busd,
  },
]

export default farms
