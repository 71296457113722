import { MenuEntry, menuStatus } from "@pancakeswap/uikit"
import { ContextApi } from "contexts/Localization/types"

const config: (t: ContextApi["t"]) => MenuEntry[] = (t) => [
  {
    label: t("Home"),
    icon: "HomeIcon",
    href: "/",
  },
  {
    label: t("Bulltrade"),
    icon: "TradeIcon",
    items: [
      {
        label: t("Exchange"),
        href: "/swap",
      },
      {
        label: t("Liquidity"),
        href: "/liquidity",
      },
    ],
  },
  {
    label: t("Bullfarms"),
    icon: "FarmIcon",
    href: "/farms",
  },
  {
    label: t("Bullpools"),
    icon: "PoolIcon",
    href: "/pools",
  },
  {
    label: t("BullChain"),
    icon: "GroupsIcon",
    href: "/referral",
  },
  {
    label: t("Bullpit"),
    icon: "InfoIcon",
    items: [
      // {
      //   label: t("IBullSwap"),
      //   href: "/info",
      // },
      {
        label: t("Poocoin"),
        href: "https://poocoin.app/tokens/0x3a0889865caac6bCB276F52a77318b043468a51e",
        target: "_blank"

      },
      {
        label: t("Dextools"),
        href: "https://www.dextools.io/app/bsc/pair-explorer/0x3a0889865caac6bcb276f52a77318b043468a51e",
        target: "_blank"
      },
      {
        label: t("DexGuru"),
        href: "https://dex.guru/token/0x3a0889865caac6bcb276f52a77318b043468a51e-bsc",
        target: "_blank"
      },
    ],
  },
  {
    label: t("BullPot"),
    icon: "TicketIcon",
    href: "/lottery",
    status: menuStatus.SOON,
  },
  {
    label: t("Bullpredict"),
    icon: "PredictionsIcon",
    href: "/bullpredict",
    status: menuStatus.SOON,
  },
  {
    label: t("Multitokendrop"),
    icon: "IfoIcon",
    href: "https://www.multitokendrop.com",
    status: menuStatus.SOON,
  },
  {
    label: t("NFT"),
    icon: "NftIcon",
    href: "/nft",
    status: menuStatus.SOON,
  },
  {
    label: t("Community"),
    icon: "MoreIcon",
    items: [
      {
        label: t("Instagram"),
        target: "_blank",
        href: "https://www.instagram.com/p/CWZ6CIXhcqs/?utm_medium=copy_link",
        
      },
      {
        label: t("Facebook"),
        href: "https://www.facebook.com/104066302106149/posts/104073122105467",
        target: "_blank"
      },
      {
        label: t("Twitter"),
        href: "https://twitter.com/ibullswap/status/1461194096970637314?s=20",
        target: "_blank"
      },
      {
        label: t("Telegram"),
        href: "https://t.me/ibullchat",
        target: "_blank"
      },
    ],
  },
  {
    label: t("More"),
    icon: "MoreIcon",
    items: [
    {
        label: t("Whitepaper"),
        href: "https://ibullswap.finance/files/whitepaper.pdf",
        target: "_blank"
      },
      {
        label: t("GitHub"),
        href: "https://github.com/DigiblocksLLC/ibullswap",
        target: "_blank"
      },
      {
        label: t("Doc"),
        href: "https://ibullswap.finance/files/revised_document.pdf",
        target: "_blank"
      },
    ],
  },
  {
    label: t("Video Tutorials"),
    icon: "MoreIcon",
    items: [
      {
        label: t("IBullSwap Introduction"),
        href: "https://ibullswap.finance/videos/Bullswap_intro.mp4",
        target: "_blank"
      },
      {
        label: t("How to add IBULL token to wallets"),
        href: "https://ibullswap.finance/videos/How_to_add_ibull_token_to_wallets.mp4",
        target: "_blank"
      },
      {
        label: t("How to add liquidity in IBullSwap"),
        href: "https://ibullswap.finance/videos/how_to_add_iquidity_in_ibull.mp4",
        target: "_blank"
      },
      {
        label: t("How to connect IBullSwap website to Metamask and Trust wallet"),
        href: "https://ibullswap.finance/videos/how_to_connect_from_i_bull_swap_website_to_meta_mask_and_trust_wallet.mp4",
        target: "_blank"
      },
      {
        label: t("How to swap IBullSwap finance"),
        href: "https://ibullswap.finance/videos/How_to_swap_inibullswap_finance.mp4",
        target: "_blank"
      },
    ],
  },
]

export default config
